import React, { useEffect, useState } from 'react';
import './ContPage.css';

const VlogPage = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    // Fetch the posts from the PHP API
    fetch('http://167.99.58.136/get_posts.php')
      .then(response => response.json())
      .then(data => setPosts(data))
      .catch(error => console.error('Error fetching posts:', error));
  }, []);

  return (
    <div className="vlog-page">
      <div className="header-box">
        <h1>v/b log</h1>
        <p>videos and written entries</p>
      </div>

      {posts.length > 0 ? (
        posts.map(post => (
          <div className="content-box" key={post.id}>
            <h2>{post.title}</h2>
            {post.type === 'vlog' ? (
              <video controls>
                <source src={post.media_url} type="video/mp4" />
              </video>
            ) : (
              <p>{post.content}</p>
            )}
          </div>
        ))
      ) : (
        <p>Loading posts...</p>
      )}
    </div>
  );
};

export default VlogPage;