import React, { useState } from 'react';

const LoginPage = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null); // State for handling errors

  const handleLogin = async () => {
    setError(null); // Reset error before making request
    
    // Create the payload object
    const payload = {
      username: username.trim(),
      password: password.trim(),
    };
    
    // Debugging: Log the payload being sent
    console.log('Payload being sent:', payload);

    try {
      const response = await fetch('https://phoeb.org/login.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload), // Ensure payload is serialized to JSON
      });

      // Debugging: Log the raw response status and status text
      console.log('Response status:', response.status);
      console.log('Response status text:', response.statusText);

      if (response.ok) {
        // Try to parse JSON response
        const result = await response.json();

        // Debugging: Log the parsed JSON result
        console.log('Parsed JSON result:', result);

        if (result.status === 'success') {
          onLogin(); // Call a function to update the app state to reflect the logged-in status
        } else {
          // Handle non-success status in response
          setError(`Login failed: ${result.message}`);
        }
      } else {
        // Handle HTTP errors
        setError(`HTTP error: ${response.status} ${response.statusText}`);
      }
    } catch (error) {
      // Handle network errors or JSON parsing errors
      setError(`An error occurred: ${error.message}`);
    }
  };

  return (
    <div>
      <h2>Login</h2>
      <input
        type="text"
        placeholder="Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button onClick={handleLogin}>Login</button>
      {error && <p style={{ color: 'red' }}>{error}</p>} {/* Display errors */}
    </div>
  );
};

export default LoginPage;