import React from 'react';
import './ContPage.css';

const PhotographyPage = () => {
    return (
        <div className="photography-page">
          <div className="header-box">
            <h1>photos page</h1>
          </div>
          <div className="content-box">
            <h2>photo project 1</h2>
            <p>eventually i'll link stuff</p>
          </div>
        </div>
      );
};

export default PhotographyPage;