import React, { useState, useEffect } from 'react';

const CreatePostPage = () => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [mediaUrl, setMediaUrl] = useState('');
  const [type, setType] = useState('blog');
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    // Check if the user is authenticated
    const checkAuth = async () => {
      const response = await fetch('https://phoeb.org/check_auth.php');
      const result = await response.json();
      if (result.status === 'authenticated') {
        setAuthenticated(true);
      } else {
        window.location.href = '/login'; // Redirect to login if not authenticated
      }
    };

    checkAuth();
  }, []);

  const handleCreatePost = async () => {
    const response = await fetch('https://phoeb.org/create_post.php', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ title, content, media_url: mediaUrl, type }),
    });
    const result = await response.text(); // Adjust depending on create_post.php response
    alert(result);
  };

  if (!authenticated) return null;

  return (
    <div>
      <h2>Create New Post</h2>
      <input
        type="text"
        placeholder="Title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <textarea
        placeholder="Content"
        value={content}
        onChange={(e) => setContent(e.target.value)}
      />
      <input
        type="text"
        placeholder="Media URL"
        value={mediaUrl}
        onChange={(e) => setMediaUrl(e.target.value)}
      />
      <select value={type} onChange={(e) => setType(e.target.value)}>
        <option value="blog">Blog</option>
        <option value="vlog">Vlog</option>
      </select>
      <button onClick={handleCreatePost}>Create Post</button>
    </div>
  );
};

export default CreatePostPage;