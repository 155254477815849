import React from 'react';
import './ContPage.css';

const CodePage = () => {
  return (
    <div className="code-page">
      <div className="header-box">
        <h1>code page</h1>
      </div>
      <div className="content-box">
        <h2>project 1</h2>
        <p>details shmetails!</p>
      </div>
      <div className="content-box">
        <h2>project 2</h2>
        <p>the real third project. a serious one indeed</p>
      </div>
      <div className="content-box">
        <h2>project 3</h2>
        <p>i dios mio ! this font and it's i's and !'s !</p>
      </div>
      <div className="content-box">
        <h2>project 3</h2>
        <p>this site- made by me over the course of a single night. a labor of love! for you... ;)</p>
        <p>it's a react app, with a few pages, some css, and a little bit of routing. i hope you like it!</p>
        <p>copilot generated the previous line. i don't care if you like it to be quite honest! the first line was an attempt at being polite. i think if you've read this far you've overstayed your welcome. shoo!</p>
        <p>...</p>
        <p>i'm sorry. i'm working on vulnerability right now and this is a work in progress. so i'm feeling sensitive about it. stay as long as you like but keep in mind it's very early in development. i've worked on stuff i just haven't linked it.</p>
      </div>
    </div>
  );
};

export default CodePage;