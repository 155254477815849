import React from 'react';
import './ContPage.css';

const AboutPage = () => {
  return (
    <div className="about-page">
      <div className="header-box">
        <h1>about</h1>
      </div>
      <div className="content-box">
        <h2>me</h2>
        <p>hello! my name is phoebe alexandria corwin. i've gone by phoebe for a while but i prefer alex these days.</p>
        <p>i'm making this site because... i can? more managed the making of it and fine-tuned details as i went along. i feel like the days of 'software engineering' are numbered thanks to the thinking machines, of course, in the likeness of human minds. oh well, it was
            fun while it lasted!</p>
        <p>i can do a lot of things! the area of focus for my degree was machine learning methods. i'm apparently good at it too, because i 
            focused on the deep learning side of it for the final projects; individually, i trained a reinforcement learning agent to successfully race around randomly generated tracks, and on a team of three, we developed an algorithm
            for pitch identification from JSON data. pretty cool stuff! i also worked on my own GAN to try and make a sort of custom art style. ho boy, those images were nightmares! it's good we have the new 
            generation of image generators because.... yikes. maybe i'll link some stuff here if i decide to dig it up. androids don't dream of electric sheep; they don't dream at all! seems like they mostly have nightmares.
            a fun prospect for the future ahead of us!</p>

        <p>
            you probably picked up on the 'apparently' towards the beginning of the last paragraph. i'm not a fan of the impostor syndrome culture but my brain tumor took a bit (most) of my memory from that time of my life, so i feel a bit like an imposter at times. i remember very little of college,
            but it holds no bearing on my ability! i just haven't been externally evaluated on my skills to decisively remove the 'apparently'. by my own assessment, i'm an adept software developer with a 
            background in machine learning!
        </p>

        <p>
            copilot keeps generating bodies of text that are just... total lies. i'm not using them but it's fun to see how highly the algorithm thinks of me. i'm certain that it's because my prompts always begin with "please" and end with "thank you".
            i don't mean to ramble, i'll get on with it.
        </p>

        <p>
            i intend to use this site as a sort of living repository of my life. i feel like that's the way things are headed, right? it's my brand, alex llc or something, eventually. i'm sure when
            stakeholders' jobs can be done by AI, they'll encourage me to put as many ads on the sides of this page as possible. until then i'll ramble. prepare for the worst and ramble like nobody's listening.
        </p>

        <p>
            if you think you or your company or your friend or whoever could benefit from someone who likes to do pretty much anything related to tech, let me know. i've worked with evolutionary robotics simulations (check out the ludobots subreddit),
            web development, c/c++ simulations for os-level operations. i have some other stuff i'm working on now in terms of machine learning, but that's somewhat under wraps until it's further along. worth coming back to check out eventually!
        </p>
        <p>          
            aside from those educational/personal endeavors, my most recent career experience was working with data ops and etl pipeline development. it was a totally custom process made in python with the intention of optimizing after initial delivery, all
            strung together with bash. it probably could've been done better with out-of-the-box tools but i did all i could with the permissions granted and the tools i was authorized to use, and it was a great learning experience. unfortunately, i was laid off before
            receiving any feedback because my previous company shifted gears in order to make the collection of data a more manual but less expensive process. the industry giveth and taketh away.
        </p>
        <p>
            i'll add a contact block under this. a job would be great but im sure another arrangement could be worked out for the right opportunity. not like this is a highlights reel to an illustrious career, it's more of like a stream of consciousness as i put up my first draft of the site. 
            also, i didn't list the programming languages i work with. why would i do that? i feel like that limits the opportunities. gimme em all! throw me into a pit with C#, i'll come out on top. look at this site! all react, 90% of it learned on the fly. in a week i'll be a pro. i'm a utilitarian polyglot.
            if i were a philosopher instead of a software developer, you'd catch me learning German when i wanted to read Nietzsche or Russian for... Tolstoy? i don't know. maybe i should've picked a field i knew more about for this metaphor.        </p>

        <p> 
            point is i'm not a philosopher, i'm a software developer. and a pretty good one, too! but hey, maybe ai will make me into something in the middle. wouldn't that be awful? hearing more of my stream of consciousness?
            </p>
            <p>
            anyways, four dollars a pound. not linking my contact info anywhere else on the site because it isn't a shameless advertisement (yet). thanks for reading
        </p>
        </div>
        <div className="content-box">
            <h2>contact</h2>
            <p>email:
                <a href="mailto:phoebe.corwin23@gmail.com"> phoebe.corwin23@gmail.com</a>
            </p>
        </div>
    </div>
  );
};

export default AboutPage;