import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import HomePage from './components/HomePage';
import AboutPage from './components/AboutPage';
import CodePage from './components/CodePage';
import PhotographyPage from './components/PhotographyPage';
import MusicPage from './components/MusicPage';
import VlogPage from './components/VlogPage';
import LoginPage from './components/LoginPage';
import CreatePostPage from './components/CreatePostPage';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/code" element={<CodePage />} />
        <Route path="/photography" element={<PhotographyPage />} />
        <Route path="/music" element={<MusicPage />} />
        <Route path="/vlog" element={<VlogPage />} />
        <Route
          path="/login"
          element={isAuthenticated ? <Navigate to="/create-post" /> : <LoginPage onLogin={handleLogin} />}
        />
        <Route
          path="/create-post"
          element={isAuthenticated ? <CreatePostPage onLogout={handleLogout} /> : <Navigate to="/login" />}
        />
      </Routes>
    </Router>
  );
}

export default App;
